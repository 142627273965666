import React, { FC, HTMLProps, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Availability, formatPrice } from "@rendpro/core-ui";
import { Icon } from "@iconify/react";
import ArrowButton from "@components/atoms/ArrowButton/ArrowButton";
import { PropertyType } from "@interfaces/index";
import { Link } from "gatsby";
import { getIconFromPropertyType } from "@utils/getIconFromPropertyType";
import { getPropertyName } from "@utils/getPropertyName";
import { transformDescription } from "@utils/transformDescription";
import ReactHtmlParser from "react-html-parser";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";
import { motion } from "framer-motion";
import { cssEaseOutExpo, easeOutCirc } from "@theme/easings";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "react-use";

const StyledWrapper = styled(motion.article)`
  display: flex;
  padding-bottom: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.washLighter};
  align-items: center;
  position: relative;

  @media (max-width: 1480px) {
    align-items: flex-start;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 700px;
  }
`;

const StyledImageWrapper = styled(Link)`
  margin-right: 100px;
  overflow: hidden;
  position: relative;

  @media (max-width: 1024px) {
    margin-right: 0;
  }
`;

const StyledImageInnerWrapper = styled.div`
  max-width: 670px;
  width: 41vw;
  height: 430px;
  transform: scale(1);
  transition: transform 1s ${cssEaseOutExpo};
  display: block;

  ${StyledImageWrapper}:hover & {
    transform: scale(1.1);
  }

  &.is-hover {
    transform: scale(1.1);
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: unset;
    height: unset;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledRightWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
`;

const StyledHeadline = styled.h3<{ $isLogo: boolean }>`
  text-transform: uppercase;
  font-size: 3.4rem;
  font-weight: 700;
  margin: 0 0 30px;

  a {
    color: inherit;
    text-decoration: none;
    position: relative;

    ${withLineHoverEffect()};
  }

  ${({ $isLogo }) =>
    $isLogo &&
    css`
      @media (min-width: 1240px) {
        padding-right: 125px;
      }
    `}
`;

const StyledAvailability = styled.div<{ $availability: Availability }>`
  padding: 8px 15px;
  background: ${({ theme, $availability }) => theme[$availability]};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 0 25px;
`;

const StyledPropertiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const StyledProperty = styled.div`
  margin-right: 25px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  svg {
    font-size: 2.2rem;
    margin-right: 12px;
  }

  :last-of-type {
    margin-right: 0;
  }

  span {
    line-height: 1px;
  }
`;

const StyledDescription = styled.p`
  line-height: 1.8;
  max-width: 640px;
  margin: 0;

  strong {
    font-weight: 700;
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }

  div {
    display: inline;
  }
`;

const StyledBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;

  @media (max-width: 1340px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const StyledPrice = styled.p`
  font-size: 3.4rem;
  margin: 0;

  span {
    font-weight: 700;
  }
`;

const StyledArrowButton = styled(ArrowButton)`
  font-weight: 400;
  text-decoration: none;
  font-size: 1.6rem;

  @media (max-width: 1340px) {
    margin-top: 30px;
  }
`;

const StyledLogo = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 85px;
  height: auto;

  @media (max-width: 1240px) {
    display: none;
  }
`;

const Investment: FC<Props> = ({
  image,
  title,
  availability,
  properties,
  description,
  price,
  priceAltText,
  logo,
  slug,
  ...props
}) => {
  const { width } = useWindowSize();
  const { ref, inView } = useInView({ threshold: width <= 1024 ? 0.1 : 0.3 });
  const headlineRef = useRef<HTMLElement>();
  const buttonRef = useRef<HTMLElement>();
  const imageWrapper = useRef<HTMLElement>();

  const handleMouseEnter = () => {
    imageWrapper.current?.classList.toggle("is-hover");
  };

  const handleMouseLeave = () => {
    imageWrapper.current?.classList.toggle("is-hover");
  };

  useEffect(() => {
    headlineRef.current?.addEventListener("mouseenter", handleMouseEnter);
    buttonRef.current?.addEventListener("mouseenter", handleMouseEnter);
    buttonRef.current?.addEventListener("mouseleave", handleMouseLeave);
    headlineRef.current?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      headlineRef.current?.removeEventListener("mouseenter", handleMouseEnter);
      buttonRef.current?.removeEventListener("mouseenter", handleMouseEnter);
      buttonRef.current?.removeEventListener("mouseleave", handleMouseLeave);
      headlineRef.current?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [headlineRef, buttonRef]);

  return (
    <StyledWrapper
      initial={{ opacity: 0, y: 100, scale: 0.9 }}
      animate={inView && { opacity: 1, y: 0, scale: 1 }}
      transition={{ duration: 1, ease: easeOutCirc }}
      ref={ref}
      {...(props as unknown)}
    >
      <StyledImageWrapper to={slug} aria-label={title}>
        <StyledImageInnerWrapper ref={imageWrapper as any}>
          <StyledImage image={getImage(image)} alt={title} />
        </StyledImageInnerWrapper>
      </StyledImageWrapper>
      <StyledRightWrapper>
        <StyledAvailability $availability={availability}>
          {availability}
        </StyledAvailability>
        <StyledHeadline $isLogo={!!logo}>
          <Link to={slug} ref={headlineRef as any}>
            {title}
          </Link>
        </StyledHeadline>
        <StyledPropertiesWrapper>
          {properties.slice(0, 3).map(({ type, content }) => (
            <StyledProperty key={type}>
              <Icon icon={getIconFromPropertyType(type)} />
              <span>
                {getPropertyName(type)}: {content}
              </span>
            </StyledProperty>
          ))}
        </StyledPropertiesWrapper>
        <StyledDescription>
          {ReactHtmlParser(transformDescription(description))}...
        </StyledDescription>
        <StyledBottomWrapper>
          <StyledPrice>
            {priceAltText ? (
              <span>{priceAltText}</span>
            ) : (
              <>
                Cena: <span>{price ? formatPrice(price) : "Ukryta"}</span>
              </>
            )}
          </StyledPrice>
          <StyledArrowButton
            forwardedAs={Link}
            to={slug}
            ref={buttonRef as any}
          >
            Dowiedz się wiecej
          </StyledArrowButton>
        </StyledBottomWrapper>
      </StyledRightWrapper>
      {logo && <StyledLogo src={logo} alt={title} />}
    </StyledWrapper>
  );
};

interface Props extends HTMLProps<HTMLDivElement> {
  image: ImageDataLike;
  title: string;
  slug: string;
  availability: Availability;
  properties: PropertyType[];
  description: string;
  price?: number;
  priceAltText?: string;
  logo?: string;
}

export default Investment;
