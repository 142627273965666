import React, { FC, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";
import { easeInOutCirc } from "@theme/easings";

export const AnimatedTextWrapper = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
`;

const StyledMotionSpan = styled(motion.span)`
  display: block;
`;

export const AnimatedText: FC<Props> = ({
  className,
  children,
  start,
  delay,
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (start) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, start]);

  const variants = {
    visible: {
      y: 0,
    },
    hidden: {
      y: "100%",
    },
  };

  return (
    <AnimatedTextWrapper>
      <StyledMotionSpan
        className={className}
        initial={{ y: "100%" }}
        animate={controls}
        transition={{ ease: easeInOutCirc, duration: 1, delay }}
        variants={variants}
      >
        {children}
      </StyledMotionSpan>
    </AnimatedTextWrapper>
  );
};

interface Props {
  className?: string;
  delay?: number;
  start?: boolean;
}

export default AnimatedText;
