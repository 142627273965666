import React, { forwardRef } from "react";
import styled from "styled-components";
import arrowIcon from "@iconify/icons-clarity/arrow-line";
import { Icon } from "@iconify/react";
import { cssEaseOutExpo } from "@theme/easings";

const StyledWrapper = styled.button<{ $iconPosition: "left" | "right" }>`
  background: transparent;
  color: ${({ theme }) => theme.foreground};
  border: 0;
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    font-size: 5rem;
    transform: ${({ $iconPosition }) =>
      $iconPosition === "left" ? "rotate(-90deg)" : "rotate(90deg)"};
    margin: ${({ $iconPosition }) =>
      $iconPosition === "left" ? "0 10px 0" : "0 0 0 10px"};
    transition: transform 0.5s ${cssEaseOutExpo};
  }

  :hover {
    svg {
      transform: ${({ $iconPosition }) =>
        $iconPosition === "left"
          ? "translate(-5px) rotate(-90deg)"
          : "translate(5px) rotate(90deg)"};
    }
  }

  @media (max-width: 720px) {
    font-size: 1.6rem;

    svg {
      font-size: 3rem;
    }
  }
`;

const ArrowButton = forwardRef<HTMLButtonElement, Props>(
  ({ iconPosition = "right", children, ...props }, ref) => (
    <StyledWrapper $iconPosition={iconPosition} ref={ref} {...(props as any)}>
      {iconPosition === "left" && <Icon icon={arrowIcon} />}
      {children}
      {iconPosition === "right" && <Icon icon={arrowIcon} />}
    </StyledWrapper>
  )
);

interface Props extends React.HTMLProps<HTMLButtonElement> {
  iconPosition?: "left" | "right";
}

export default ArrowButton;
