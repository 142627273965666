import React, { FC, HTMLProps } from "react";
import styled from "styled-components";
import { cssEaseInOutCirc, cssEaseOutExpo } from "@theme/easings";

const StyledButton = styled.button`
  width: 140px;
  height: 140px;
  background: transparent;
  border-radius: 50%;
  display: flex;
  border: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.foreground};
  cursor: pointer;
  transition: color 1s ${cssEaseOutExpo}, border 1s ${cssEaseOutExpo};
  position: relative;

  :active {
    transform: scale(0.9);
  }

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  svg:last-of-type circle {
    stroke: ${({ theme }) => theme.primary};
  }
  
  svg circle {
    transition: stroke-dashoffset 1.5s ${cssEaseInOutCirc};
  }

  :hover {
    color: ${({ theme }) => theme.primary};

    svg:last-of-type  circle {
      stroke-dashoffset: 0;
    }
    
    svg  circle {
      stroke-dashoffset: 245;
    }
  }
}

    @media (max-width: 720px) {
      width: 120px;
      height: 120px;
    }
`;

const LoadMoreButton: FC<HTMLProps<HTMLButtonElement>> = ({
  children,
  ...props
}) => (
  <StyledButton {...(props as unknown)}>
    <span>Załaduj</span> <span>więcej</span>
    <svg viewBox="0 0 80 80">
      <g transform="translate(-50.69 -76.033)">
        <circle
          cx="90.69"
          cy="116.03"
          r="39"
          fill="transparent"
          stroke="#fff"
          strokeWidth="2"
          strokeDasharray="245 0"
          strokeDashoffset="0"
        />
      </g>
    </svg>
    <svg viewBox="0 0 80 80">
      <g transform="translate(-50.69 -76.033)">
        <circle
          cx="90.69"
          cy="116.03"
          r="39"
          fill="transparent"
          stroke="#fff"
          strokeWidth="2"
          strokeDasharray="245"
          strokeDashoffset="245"
        />
      </g>
    </svg>
  </StyledButton>
);

export default LoadMoreButton;
