import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Headline from "@components/atoms/Headline/Headline";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp, PropertyType } from "@interfaces/index";
import { Availability } from "@rendpro/core-ui";
import Investment from "@components/molecules/Investment/Investment";
import LoadMoreButton from "@components/atoms/LoadMoreButton/LoadMoreButton";
import Stars from "@components/atoms/Stars/Stars";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const StyledWrapper = styled.section`
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 220px 0 320px;
  margin-top: 160px;
  position: relative;

  @media (max-width: 1024px) {
    padding: 140px 0 80px;
    margin-top: 120px;
  }

  @media (max-width: 420px) {
    margin-top: 80px;
    padding: 90px 0 80px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1640px;
  margin: auto;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledHeader = styled.header`
  margin-bottom: 140px;

  @media (max-width: 420px) {
    margin-bottom: 80px;
  }
`;

const StyledInvestmentWrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledLoadMoreButton = styled(LoadMoreButton)`
  margin: 100px auto 0;

  @media (max-width: 720px) {
    margin: 40px auto 0;
  }
`;

const StyledStars = styled(Stars)`
  position: absolute;
  right: 75px;
  bottom: 120px;
  transform: rotate(180deg);
`;

const InvestmentsSection = () => {
  const { directus } = useStaticQuery<Query>(query);
  const [maxInvestments, setMaxInvestments] = useState<number>(4);
  const { scroll } = useLocomotiveScroll();

  const handleButtonClick = () => {
    setMaxInvestments((prevState) => prevState + 4);
  };

  useEffect(() => {
    if (maxInvestments !== 4) {
      scroll?.update();
    }
  }, [maxInvestments]);

  return (
    <StyledWrapper id="investments" data-scroll-section="">
      <StyledInnerWrapper>
        <StyledHeader data-scroll="" data-scroll-speed="2">
          <Headline>Wybierz swoje diamentowe zacisze</Headline>
        </StyledHeader>

        {directus.investment
          .slice(0, maxInvestments)
          .map(
            (
              {
                description,
                properties,
                availability,
                feature_image,
                name,
                price,
                logo,
                slug,
                price_alt_text,
              },
              index
            ) => (
              <StyledInvestmentWrapper
                data-scroll=""
                data-scroll-speed="1"
                key={index}
              >
                <Investment
                  image={feature_image.imageFile.childImageSharp}
                  title={name}
                  availability={availability}
                  properties={properties}
                  description={description}
                  price={price}
                  logo={logo?.imageFile?.publicURL}
                  slug={slug}
                  priceAltText={price_alt_text}
                />
              </StyledInvestmentWrapper>
            )
          )}
      </StyledInnerWrapper>

      {maxInvestments < directus.investment.length && (
        <div data-scroll="" data-scroll-speed="1">
          <StyledLoadMoreButton onClick={handleButtonClick} />
        </div>
      )}

      <StyledStars />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    directus {
      investment(filter: { is_archived: { _eq: false } }) {
        id
        name
        slug
        feature_image {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
            }
          }
        }
        description
        price
        price_alt_text
        properties
        availability
        is_archived
        logo {
          id
          imageFile {
            publicURL
          }
        }
      }
    }
  }
`;

interface Query {
  directus: {
    investment: {
      name: string;
      slug: string;
      feature_image: {
        id: string;
        imageFile: ChildImageSharp;
      };
      description: string;
      price?: number;
      price_alt_text?: string;
      properties: PropertyType[];
      availability: Availability;
      is_archived: boolean;
      logo: {
        id: string;
        imageFile: {
          publicURL: string;
        };
      };
    }[];
  };
}

export default InvestmentsSection;
