import React, { useState } from "react";
import styled, { css } from "styled-components";
import Headline from "@components/atoms/Headline/Headline";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/index";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import OtherInvestment from "@components/molecules/OtherInvestment/OtherInvestment";
import { getImage } from "gatsby-plugin-image";
import { Icon } from "@iconify/react";
import arrowIcon from "@iconify/icons-clarity/arrow-line";
import { cssEaseOutExpo } from "@theme/easings";
import Stars from "@components/atoms/Stars/Stars";

SwiperCore.use([Navigation]);

const StyledWrapper = styled.section`
  padding: 220px 0 340px;
  max-width: 1920px;
  margin: auto;
  position: relative;

  header {
    margin-bottom: 140px;
  }

  @media (max-width: 1024px) {
    padding: 140px 0 80px;
  }

  @media (max-width: 420px) {
    padding: 80px 0 60px;
    header {
      margin-bottom: 70px;
    }
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1700px;
  margin-left: auto;

  @media (max-width: 1024px) {
    margin-right: auto;
  }

  > span {
    display: block;
    margin-top: -60px;
    margin-bottom: 60px;

    @media (max-width: 1024px) {
      width: 100%;
      text-align: center;
    }

    @media (max-width: 420px) {
      margin-top: 0;
    }
  }
`;

const SwiperWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;

  .swiper {
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }

  .swiper-wrapper {
    display: inline-flex;
  }

  .swiper-slide {
    opacity: 0.5;
    transition: opacity 1s ${cssEaseOutExpo};
  }

  .swiper-slide-active {
    opacity: 1;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  bottom: 0;
  right: 20%;
  transform: translateX(-100%);
  position: absolute;
  z-index: 99999;

  @media (max-width: 1024px) {
    position: static;
    transform: unset;
    justify-content: center;
    margin-top: 40px;
  }

  @media (max-width: 520px) {
    margin-top: 40px;
  }
`;

const StyledButton = styled.button<{ $left?: boolean }>`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  font-size: 3rem;
  background: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }

  :active {
    transform: scale(0.9);
  }

  ${({ $left }) =>
    $left &&
    css`
      margin-right: 50px;
      svg {
        transform: rotate(-90deg);
      }
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.washLight};
      border: 1px solid ${theme.washLight};
      cursor: default;

      :active {
        transform: scale(1);
      }
    `}
`;

const StyledStars = styled(Stars)`
  left: 120px;
  bottom: 120px;
  position: absolute;
  z-index: 9999;
`;

const OtherInvestmentsSection = () => {
  const { directus } = useStaticQuery<Query>(query);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <StyledWrapper data-scroll-section id="other_investments">
      <StyledInnerWrapper>
        <header data-scroll="" data-scroll-speed="2">
          <Headline>Inne inwestycje</Headline>
        </header>

        {directus.investment.length > 0 ? (
          <SwiperWrapper data-scroll="" data-scroll-speed="1">
            <Swiper
              grabCursor
              updateOnWindowResize
              slidesPerView={1}
              spaceBetween={120}
              speed={1000}
              breakpoints={{
                1025: {
                  slidesPerView: "auto",
                },
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
              navigation={{
                nextEl: "#next-button",
                prevEl: "#prev-button",
              }}
              slidesOffsetAfter={
                typeof window !== "undefined" && window.innerWidth * 0.59
              }
            >
              {directus.investment.map(({ feature_image, title }) => (
                <SwiperSlide key={feature_image.id}>
                  <OtherInvestment
                    image={getImage(feature_image.imageFile.childImageSharp)}
                    description={title}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <StyledButtonsWrapper>
              <StyledButton
                aria-label="Poprzednia inwestycja"
                $left
                id="prev-button"
                disabled={activeIndex === 0}
              >
                <Icon icon={arrowIcon} />
              </StyledButton>
              <StyledButton
                aria-label="Następna"
                id="next-button"
                disabled={activeIndex === directus.investment.length - 1}
              >
                <Icon icon={arrowIcon} />
              </StyledButton>
            </StyledButtonsWrapper>
          </SwiperWrapper>
        ) : (
          <span data-scroll="" data-scroll-speed="1">
            Brak innych inwestycji
          </span>
        )}
      </StyledInnerWrapper>

      <StyledStars />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    directus {
      investment(filter: { is_archived: { _eq: true } }) {
        is_archived
        feature_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
            }
          }
        }
        title
      }
    }
  }
`;

interface Query {
  directus: {
    investment: {
      is_archived: boolean;
      feature_image: {
        id: string;
        imageFile: ChildImageSharp;
      };
      title: string;
    }[];
  };
}

export default OtherInvestmentsSection;
