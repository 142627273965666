import React, { FC } from "react";
import styled from "styled-components";
import ArrowButton from "@components/atoms/ArrowButton/ArrowButton";
import Stars from "@components/atoms/Stars/Stars";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import { graphql, useStaticQuery } from "gatsby";

const StyledWrapper = styled.section`
  padding: 220px 0 160px;
  width: 90%;
  max-width: 1480px;
  margin: auto;
  position: relative;

  @media (max-width: 1024px) {
    padding: 110px 0 0;
    max-width: 700px;
  }

  @media (max-width: 420px) {
    padding: 80px 0 0;
  }
`;

const StyledFirstRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledLeftColumn = styled.div`
  font-family: "Caveat", sans-serif;

  h2 {
    font-weight: 700;
    font-size: 6.4rem;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    font-size: 4.2rem;
    margin: 0 0 0 90px;
  }

  @media (max-width: 1480px) {
    p {
      margin: 0;
    }
  }

  @media (max-width: 1380px) {
    p {
      font-size: 3.2rem;
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 3.8rem;
    }

    p {
      font-size: 2.4rem;
    }
  }
`;

const StyledLine = styled.span`
  display: block;
  margin-top: 25px;
  height: 2px;
  width: 70%;
  background: ${({ theme }) => theme.washLighter};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledRightColumn = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media (max-width: 740px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 50px;
    margin-right: -50px;
    width: calc(100% - 50px);
  }
`;

const StyledStatistic = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 180px;
  align-items: center;
  margin-right: 100px;

  :last-of-type {
    margin-right: 0;
  }

  @media (max-width: 1480px) {
    margin-right: 50px;
  }

  @media (max-width: 1180px) {
    margin-right: 10px;
    width: 160px;
  }

  @media (max-width: 1024px) {
    width: 180px;
  }

  @media (max-width: 1024px) {
    & {
      margin-right: 50px;
    }
  }

  @media (max-width: 740px) {
    &,
    :last-of-type {
      margin-right: 50px;
    }
  }
`;

const StyledCount = styled.span`
  font-size: 6.4rem;
  font-weight: 700;
  margin: 0 0 5px;

  @media (max-width: 1024px) {
    font-size: 5.2rem;
  }
`;

const StyledStatisticName = styled.h3`
  line-height: 1.8;
  font-weight: 400;
  font-size: 1.6rem;

  @media (max-width: 1180px) {
    font-size: 1.4rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;

const StyledSecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin-right: 280px;
  margin-top: 80px;
  align-items: flex-end;

  @media (max-width: 1180px) {
    max-width: 900px;
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
  }

  @media (max-width: 420px) {
    margin-top: 40px;
  }
`;

const StyledButton = styled(ArrowButton)<{ forwardedAs: any }>`
  @media (max-width: 1024px) {
    margin-top: 30px;
  }
`;

const StyledParagraph = styled.p`
  max-width: 500px;
  line-height: 1.8;
  margin: 0;

  span {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledStars = styled(Stars)`
  position: absolute;
  right: 0;
  bottom: 0;

  @media (max-width: 1180px) {
    display: none;
  }
`;

const Statistics: FC = () => {
  const { directus } = useStaticQuery<Query>(query);

  return (
    <StyledWrapper data-scroll-section="" id="statistics">
      <StyledFirstRow data-scroll="" data-scroll-speed="1">
        <StyledLeftColumn>
          <h2>Dom...</h2>
          <p>...Tu zaczyna się Twoja historia...</p>
          <StyledLine />
        </StyledLeftColumn>
        <StyledRightColumn>
          {directus.settings.statistics.map(({ statistic_id }, id) => (
            <StyledStatistic key={id}>
              <StyledStatisticName>{statistic_id.text}</StyledStatisticName>
              <StyledCount> {statistic_id.value}</StyledCount>
            </StyledStatistic>
          ))}
        </StyledRightColumn>
      </StyledFirstRow>

      <StyledSecondRow data-scroll="" data-scroll-speed="2">
        <StyledButton forwardedAs={ScrollTo} href="/#investments">
          Inwestycje
        </StyledButton>
        <StyledParagraph>
          Wiemy, że dom to <span>wyjątkowe miejsce</span> gdzie tworzy się
          historia naszego życia. Dlatego też projekty domów i mieszkań na
          osiedlach Diamentowe Zacisze tworzymy <span>indywidualnie</span>,
          przez <span>doświadczony zespół z pasją</span> do nieruchomości.
          Najważniejsze cechy to funkcjonalność, ekologia i prestiż.
        </StyledParagraph>
      </StyledSecondRow>

      <StyledStars />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    directus {
      settings {
        statistics {
          statistic_id {
            text
            value
          }
        }
      }
    }
  }
`;

interface Query {
  directus: {
    settings: {
      statistics: {
        statistic_id: {
          text: string;
          value: string;
        };
      }[];
    };
  };
}

export default Statistics;
