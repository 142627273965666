import React, { FC } from "react";
import BasicTemplate from "@templates/BasicTemplate";
import Hero from "@components/molecules/Hero/Hero";
import Statistics from "@components/molecules/Statistics/Statistics";
import InvestmentsSection from "@components/organisms/InvestmentsSection/InvestmentsSection";
import OtherInvestmentsSection from "@components/organisms/OtherInvestmentsSection/OtherInvestmentsSection";
import AboutUs from "@components/molecules/AboutUs/AboutUs";
import Contact from "@components/molecules/Contact/Contact";
import { graphql } from "gatsby";

const Index: FC<Props> = ({ data }) => (
  <BasicTemplate
    renderHero={Hero}
    title={data.directus.settings.seo_title}
    description={data.directus.settings.seo_description}
    ogImage={
      data.directus.settings.seo_image.imageFile.childImageSharp.fixed.src
    }
  >
    <Statistics />
    <InvestmentsSection />
    <OtherInvestmentsSection />
    <AboutUs />
    <Contact />
  </BasicTemplate>
);

export const query = graphql`
  {
    directus {
      settings {
        seo_title
        seo_description
        seo_image {
          id
          imageFile {
            childImageSharp {
              fixed(quality: 100, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    directus: {
      settings: {
        seo_title: string;
        seo_description: string;
        id: string;
        seo_image: {
          imageFile: {
            childImageSharp: {
              fixed: {
                src: string;
              };
            };
          };
        };
      };
    };
  };
}

export default Index;
