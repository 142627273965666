import React, { FC } from "react";
import ArrowButton from "@components/atoms/ArrowButton/ArrowButton";
import styled from "styled-components";

const StyledArrowButton = styled(ArrowButton)`
  position: absolute;
  right: 65px;
  bottom: 100px;
  z-index: 1;
  transform: rotate(-90deg) translate(50%, 50%);

  @media (max-width: 1024px) {
    right: 0;
    transform: rotate(-90deg) translate(50%, 80%);
    bottom: 60px;
  }
`;

const ScrollBottom: FC<Props> = ({ onClick }) => (
  <div data-scroll="" data-scroll-speed="2">
    <StyledArrowButton iconPosition="left" onClick={onClick}>
      przewiń
    </StyledArrowButton>
  </div>
);

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default ScrollBottom;
