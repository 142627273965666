import React, { FC, useContext } from "react";
import styled, { css } from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/index";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ScrollBottom from "@components/molecules/Hero/ScrollBottom/ScrollBottom";
import Button from "@components/atoms/Button/Button";
import Decore from "@assets/svg/decore.svg";
import AnimatedText from "@components/atoms/AnimatedText/AnimatedText";
import { MainContext } from "@context/index";
import { motion } from "framer-motion";
import { cssEaseOutExpo, easeInOutCirc, easeOutCirc } from "@theme/easings";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { useSlider } from "@hooks/useSlider";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh) * 100);
  position: relative;
  min-height: 720px;

  @media (max-width: 720px) {
    min-height: 400px;
  }
`;

const StyledPaginationWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 60px;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
`;

const StyledPaginationItem = styled.span<{ $isActive: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.washLight};
  padding: 5px;
  cursor: pointer;
  margin-right: 40px;
  transition: background 1s ${cssEaseOutExpo};

  :last-of-type {
    margin-right: 0;
  }

  :hover {
    background: #fff;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${({ theme }) => theme.primary};
    `}
`;

const StyledSliderWrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const StyledSliderInnerWrapper = styled.div`
  display: inline-flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  will-change: transform;
  transition: transform 2s ${cssEaseOutExpo};
`;

const StyledSlide = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
`;

const StyledImage = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;

  ::before {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background: #000;
    opacity: 0.7;
    z-index: 1;
  }
`;

const StyledContentWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 80%;
  height: 100%;
  max-width: 1400px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`;

const StyledContentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: min-content;

  h3 {
    order: -1;
    text-transform: uppercase;
    color: ${({ theme }) => theme.primary};
    font-size: 3rem;
    margin: 0 0 15px;
   
    @media (min-width: 1025px) {
      white-space: nowrap;
    }
  }

  h2 {
    font-size: 11rem;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
  }

  p {
    margin: 0 0 35px;
    width: 100%;
    line-height: 1.5;
  }

  @media (max-width: 1270px) {
    h3 {
      font-size: 2.4rem;
    }

    h2 {
      font-size: 9rem;
    }
  }

  @media (max-width: 720px) {
    h3 {
      font-size: 1.8rem;
    }

    h2 {
      font-size: 6rem;
    }
  }

  @media (max-width: 460px) {
    h3 {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 3.4rem;
    }

    p {
      font-size: 1.4rem;
      margin: 0 0 30px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      visibility: visible;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const StyledHeadlineWrapper = styled.div`
  position: relative;
  margin: 0 0 30px;

  @media (max-width: 720px) {
    margin: 0 0 10px;
  }
`;

const StyledButton = styled(Button)`
  font-weight: 700;
  display: flex;
`;

const StyledDecoreWrapper = styled.div`
  position: absolute;
  left: -5%;
  bottom: 0;
  width: 125%;
  height: 20px;
  z-index: -1;
  overflow: hidden;
`;

const StyledDecore = styled(Decore)`
  width: 100%;
  height: 100%;
`;

const Hero: FC = () => {
  const {
    directus: { slider },
  } = useStaticQuery<Query>(query);
  const { isLoading } = useContext(MainContext);
  const { scroll } = useLocomotiveScroll();
  const { slideTo, currentActive } = useSlider({
    count: slider.length,
    timeout: 8000,
  });

  const handleButtonClick = () => {
    scroll?.scrollTo("#statistics");
  };

  return (
    <StyledWrapper data-scroll-section="">
      <StyledSliderWrapper>
        <StyledSliderInnerWrapper
          style={{
            transform: `translateX(-${100 * currentActive}%)`,
          }}
        >
          {slider.map(({ description, investment, id, title }) => (
            <StyledSlide key={id}>
              <StyledImage
                image={getImage(
                  investment.feature_image.imageFile.childImageSharp
                )}
                alt=""
              />
              <StyledContentWrapper>
                <StyledContentInnerWrapper>
                  <h3 data-scroll="" data-scroll-speed="1">
                    <motion.span
                      initial={{
                        opacity: 0,
                      }}
                      animate={!isLoading && { opacity: 1 }}
                      transition={{
                        ease: easeInOutCirc,
                        transition: 1.5,
                        delay: 1.3,
                      }}
                    >
                      {investment.name}
                    </motion.span>
                  </h3>
                  <StyledHeadlineWrapper data-scroll data-scroll-speed="1">
                    <h2>
                      {title.split(" ").map((value) => (
                        <React.Fragment key={value}>
                          <AnimatedText start={!isLoading} delay={0.8}>
                            {value}
                          </AnimatedText>{" "}
                        </React.Fragment>
                      ))}
                    </h2>
                    <StyledDecoreWrapper>
                      <motion.div
                        initial={{
                          translateX: "-50%",
                          transformOrigin: "top left",
                          opacity: 0,
                        }}
                        animate={!isLoading && { translateX: 0, opacity: 1 }}
                        transition={{
                          ease: easeOutCirc,
                          transition: 1,
                          delay: 1.3,
                        }}
                      >
                        <StyledDecore />
                      </motion.div>
                    </StyledDecoreWrapper>
                  </StyledHeadlineWrapper>
                  <p data-scroll="" data-scroll-speed="1">
                    <motion.span
                      initial={{
                        opacity: 0,
                      }}
                      animate={!isLoading && { opacity: 1 }}
                      transition={{
                        ease: easeInOutCirc,
                        transition: 1.5,
                        delay: 1.4,
                      }}
                    >
                      {description}
                    </motion.span>
                  </p>
                  <div data-scroll="" data-scroll-speed="1">
                    <motion.div
                      initial={{
                        opacity: 0,
                      }}
                      animate={!isLoading && { opacity: 1 }}
                      transition={{
                        ease: easeInOutCirc,
                        transition: 1.5,
                        delay: 1.5,
                      }}
                    >
                      <StyledButton
                        to={`/${investment.slug}`}
                        forwardedAs={Link}
                      >
                        Zobacz więcej
                      </StyledButton>
                    </motion.div>
                  </div>
                </StyledContentInnerWrapper>
              </StyledContentWrapper>
            </StyledSlide>
          ))}
        </StyledSliderInnerWrapper>
      </StyledSliderWrapper>
      <StyledPaginationWrapper>
        {slider.map((_, index) => (
          <StyledPaginationItem
            $isActive={currentActive === index}
            onClick={() => slideTo(index)}
            key={index}
          />
        ))}
      </StyledPaginationWrapper>
      <ScrollBottom onClick={handleButtonClick} />
    </StyledWrapper>
  );
};

interface Query {
  directus: {
    slider: {
      id: string;
      description: string;
      title: string;
      investment: {
        name: string;
        slug: string;
        feature_image: {
          id: string;
          imageFile: ChildImageSharp;
        };
      };
    }[];
  };
}

const query = graphql`
  {
    directus {
      slider {
        id
        description
        title
        investment {
          name
          slug
          feature_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
  }
`;

export default Hero;
