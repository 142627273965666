import React, { FC, HTMLProps } from "react";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const StyledWrapper = styled.div`
  width: 59vw;
  max-width: 1142px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 31vw;
  max-height: 812px;

  @media (max-width: 1024px) {
    height: 50vw;
  }
`;

const StyledDescription = styled.div`
  margin-top: 35px;
  font-size: 2.4rem;
  max-width: 70%;
  line-height: 1.5;

  @media (max-width: 720px) {
    font-size: 1.8rem;
    margin-top: 15px;
    width: 90%;
  }

  @media (max-width: 420px) {
    font-size: 1.6rem;
  }
`;

const OtherInvestment: FC<Props> = ({
  children,
  description,
  image,
  ...props
}) => (
  <StyledWrapper {...(props as any)}>
    <StyledImage image={image} alt={description} />
    <StyledDescription>{description}</StyledDescription>
  </StyledWrapper>
);

interface Props extends HTMLProps<HTMLDivElement> {
  image: IGatsbyImageData;
  description: string;
}

export default OtherInvestment;
