import { useContext, useEffect, useState } from "react";
import { MainContext } from "@context/index";

export const useSlider = ({
  count,
  timeout: timeoutDuration,
}: {
  count: number;
  timeout: number;
}) => {
  const { isLoaderExited } = useContext(MainContext);
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [timeout, setTimeoutState] = useState<NodeJS.Timeout>();

  const handlePrevClick = () => {
    clearTimeout(timeout);
    setCurrentActive((prevState) =>
      prevState === 0 ? count - 1 : prevState - 1
    );
  };

  const handleNextClick = () => {
    clearTimeout(timeout);
    setCurrentActive((prevState) =>
      prevState === count - 1 ? 0 : prevState + 1
    );
  };

  const slideTo = (index: number) => {
    clearTimeout(timeout);
    setCurrentActive(index);
  };

  useEffect(() => {
    if (isLoaderExited) {
      const changeSlide = () => {
        if (currentActive === count - 1) {
          setCurrentActive(0);
        } else {
          setCurrentActive((prevState) => prevState + 1);
        }
      };

      setTimeoutState(setTimeout(changeSlide, timeoutDuration));
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [currentActive, isLoaderExited]);

  return { currentActive, handleNextClick, handlePrevClick, slideTo };
};
